import React from 'react';
import Layout from 'layout';
import Link from 'components/ui/link';
import Button from 'components/ui/button';

const ContentSpecialist = () => (
  <Layout
    metaTitle="Account Executive"
    canonical="/jobs/account-executive/"
    metaDescription="We are looking for Account Executive - join us if you are looking for new challenges!"
  >
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 text-left p-4 m-auto">
            <h1 className="mb-5 text-center">Account Executive</h1>
            <p className="mb-3">
              We are looking for a Account Executive who will be responsibile for expansion sales to
              potential clients. This is a permanent, full time remote job.
            </p>
            <p className="mt-4">Our future colleague: </p>
            <ul className="">
              <li>
                has 1+ year of experience in B2B sales (experience in SaaS company is a big plus!)
              </li>
              <li>
                is fluent in English along with excellent, written and oral communication skills
                (minimum C1)
              </li>
              <li>has ability to present business value and profits to prospects</li>
              <li>is independent and takes actions proactively</li>
              <li>has experience using software like Hubspot, Salesforce, Pipedrive or similar</li>
              <li>is passionate about technologies</li>
              <li>has ability and desire to work in a fast-growing startup environment</li>
            </ul>
            <p className="mt-4">Benefits: </p>
            <ul>
              <li>salary tailored to your experience, skills, and performance</li>
              <li>fully remote with flexible working hours</li>
              <li>startup atmosphere</li>
            </ul>
            <p className="mt-4">
              Send us your CV and a few words about yourself to:{' '}
              <Link href="mailto:jobs@livesession.io">jobs@livesession.io</Link>
            </p>
            <p
              style={{
                margin: '3rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button href="mailto:jobs@livesession.io">Apply</Button>
            </p>

            <p className="mt-5" style={{ fontSize: '0.8rem', color: 'grey' }}>
              By sending a recruitment application to LiveSession Sp. z o.o. with headquarters in
              Wroclaw, at gen. Wladyslawa Sikorskiego 3/2, 56-659, Wroclaw, entered in the register
              of entrepreneurs of the National Court Register under number 0000753134, Tax ID:
              PL8971860301, REGON: 381580380, you agree to the processing of personal data contained
              in the recruitment application by the Company in order to recruit for the position
              indicated in the announcement.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContentSpecialist;
